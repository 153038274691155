import { makeAutoObservable } from 'mobx';

import { getCurrentFreshness } from '^/types/__ResultType';
import { AppContext } from '^/userWeb/types/AppContext';
import { MobxValue } from '^/utils/mobx-utils/MobxValue';

import { CowAlarmsTabViewModel } from './tabs/CowAlarmsTab.viewmodel';
import { NoticeTabViewModel } from './tabs/NoticeTab.viewmodel';
import { VisitorDetectedTabViewModel } from './tabs/VisitorDetectedTabs.viewmodel';

export class NotificationsPageViewModel {
  constructor(
    public appContext: AppContext,
    public freshness = getCurrentFreshness(),
    public tabSelection = new MobxValue(0),
  ) {
    makeAutoObservable(this);
  }

  get noticeTabViewModel() {
    return new NoticeTabViewModel(this.appContext);
  }

  get visitorDetectedTabViewModel() {
    return new VisitorDetectedTabViewModel(
      this.appContext, this.freshness,
    );
  }

  get cowAlarmsTabViewModel() {
    return new CowAlarmsTabViewModel(
      this.appContext,
      this.freshness,
    );
  }
}
